<template>
  <b-modal v-model="isCardModalActive" class="modal-container" :scroll="scroll" :can-cancel="backdropClosed">
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  /** This component contains the code for the modal that pops up on the admin panel */
  name: 'Modal',
  // props: ['onChange', 'modal'],
  props: {
    /** This prop defines the behavior  */
    onChange: Function,
    /** This prop indicates whether the modal is visible or not  */
    modal: Boolean,
    /** This prop indicates whether the modal should have a scroll or not. (Keep the value as 'clip' if you plan on having scroll on a child element in the modal) */
    scroll: {
      type: String,
      default: 'keep'
    },
    backdropClosed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isCardModalActive: null
    }
  },
  watch: {
    isCardModalActive () {
      this.$emit('onChange', this.isCardModalActive)
    },
    modal () {
      this.isCardModalActive = this.modal
    }
  },
  mounted () {
    this.isCardModalActive = this.modal
  }
}
</script>

<style lang="scss">
.modal-close {
  display:none
}

.modal-display-container {
  display: flex;
  justify-content: center;
  color: var(--secondary-text-color);
  font-family: Quicksand;
  flex-direction: column;
  background: var(--primary);
  border-radius: 5px;
  overflow: hidden;
  .modal-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 0 20px;
    p {
      font-size: 14px;
    }
  }
  .modal-header {
    background: var(--modal-header-background);
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    margin-bottom: 20px;
    text-align: left;
  }
  .actions {
    padding: 15px;
    padding-bottom: 20px;
    margin-top: 20px;
    text-align: end;
    .button {
      height: 32px;
      border: none;
      font-size: 14px;
      width: 75px;
    }
    .button:nth-child(1) {
      margin-left: 5px;
      background-color: transparent;
      color: var(--secondary-text-color);
      border: 1px solid var(--primary-border-color);
      &:hover {
        border: 2px solid var(--primary-border-color);
        transition: border 0.1s ease-out;
      }
      &:active {
        background-color: #7f8fa466;
        transition: background-color 0.1s ease-out;
      }
    }
    .button:nth-child(2) {
      margin-left: 5px;
      background-color: var(--inava-primary);
      color: white;
      &:disabled {
        background-color: var(--inava-gray);
      }
      &:hover:not(:disabled) {
        background-color: var(--inava-primary-dark);
        transition: background-color 0.1s ease-out;
      }
      &:active:not(:disabled) {
        background-color: var(--inava-primary-dark);
        transition: background-color 0.1s ease-out;
      }
    }
  }
}
</style>
