<template>
  <div class="clients-container">
    <modal-vue
      @onChange="onChangeModal"
      :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete Category</p>
          <div class="modal-data">
            <p v-if="showWarning">
              This category will impact on few subsriptions.
            </p>
            <p>
              Are you sure you want to delete this category (<b>{{deleteCategoryObject.category_name}}</b>) ?
            </p>
            <div class="actions">
              <b-button @click="onChangeModal(false)">Cancel</b-button>
              <b-button @click="removeCategory">Delete</b-button>
            </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <admin-header-vue
      v-if="showCategory"
      alias="Category"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="categories"
        :sortObj="sort"
        :canDelete="true"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :categoryFilterNeeded="false"
        :titleData="'Categories'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @onDelete="onDelete"
        @updateLimit="updateLimit">
        <dropdown-wrap-vue
          @onClickOutside="onClickOutside"
          class="column is-4 form-group">
          <div class="relative">
            <b-input
              type="search"
              ref="categories"
              @focus="onFocus"
              v-model="searchCategory"
              :placeholder="'Select Categories'"
              :icon-right="openCategories ? 'menu-up' : 'menu-down'"
              class="form-control" />
            <common-selection-vue
              :searchText="searchCategory"
              name="category_name"
              @focus="onFocus"
              :open="openCategories"
              :selected="selectedCategories"
              @onChange="onChangeCategoryId"
              :ref="'commonSelection'"
              :list="categoryList" />
          </div>
        </dropdown-wrap-vue>
      </table-vue>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'
import ModalVue from '../../../Admin/components/Modal.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'

/** This component contains the code that allows you to view the categories present in the database and their details. From here the user can view/edit existing categories and navigate to the *Add categories* page. */
export default {
  name: 'Categories',
  metaInfo () {
    return {
      title: metaTitle.categories
    }
  },
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue,
    DropdownWrapVue,
    CommonSelectionVue

  },
  data () {
    return {
      dateFormat: ['created_at', 'updated_at'],
      labels: [{ name: 'Category Id', alias: 'category_id' }, { name: 'Category Name', alias: 'category_name' }, { name: 'Products', alias: 'product' }, { name: 'Created At', alias: 'created_at' }, { name: 'Updated At', alias: 'updated_at' }],
      sortValidation: ['category_id', 'product', 'category_name', 'created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'category_id' },
      categoryFilterNeeded: true,
      modal: false,
      deleteCategoryObject: {},
      showWarning: false,
      selectedCategories: [],
      openCategories: false,
      searchCategory: null
    }
  },
  computed: {
    ...mapState('categories', ['categories', 'totalCount', 'categoryList']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showCategory () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.category.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.category.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.category.currentPage = newPage
      }
    }
  },
  watch: {
    categoryList () {
      this.selectedCategories = this.categoryList
    },
    selectedCategories () {
      if (!this.selectedCategories.length) {
        this.resetCategories()
      }
      this.getData()
    }
  },
  mounted () {
    this.getCategoryList()
  },
  methods: {
    ...mapActions('categories', ['getCategoryList', 'getCategoryListWithPagination', 'resetCategories', 'deleteCategory', 'deleteCategoryVerify']),
    /** This method closes the dropdown that controls what categories are visible in the table
     * @public
     */
    onClickOutside () {
      this.openCategories = false
    },
    /** This method opens the dropdown that controls what categories are visible in the table
     * @public
     */
    onFocus () {
      this.$refs.categories.$el.firstChild.focus()
      this.openCategories = true
    },
    /** This method updates the list of selected categories
     * @public
     */
    onChangeCategoryId (categories) {
      this.selectedCategories = categories
    },
    /** This method redirects the user to the *update category* page/form and populates it with existing category data from the database
     * @param data {Object} - The category object that contains the details for the selected category
     */
    onUpdate (data) {
      this.$router.push(`category/update/${data.category_id}`)
    },
    /** This method redirects the user to the *add category* page
     * @public
     */
    onCreate () {
      this.$router.push('category/add')
    },
    /** This method triggers the appearance of a modal to confirm a delete action for the category
     * @param data {Object} - The category object that contains the details for the selected category
     * @public
     */
    async onDelete (data) {
      this.deleteCategoryObject = data
      const res = await this.deleteCategoryVerify(this.deleteCategoryObject.category_id)
      if (res === 200) {
        this.showWarning = true
      } else if (res === 201) {
        this.showWarning = false
      }
      this.modal = true
    },
    /** This method triggers the visibility of the confirmation modal
     * @public
     */
    onChangeModal (value) {
      this.modal = value
    },
    /**
     * If a user confirms the delete action on the modal, this method deletes the category from the database
     * @public
     */
    async removeCategory () {
      const res = await this.deleteCategory(this.deleteCategoryObject.category_id)
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    /** This method updates the sorting criteria of the table
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that are being shown by the table
     * @public
     */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
     * @param text {String} - The new search keyword
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the category related data from the database, using the limit/search/sort parameters specified */
    getData () {
      if (this.selectedCategories.length) {
        this.getCategoryListWithPagination({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search, categoryList: this.selectedCategories.map(e => e.category_id) })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .clients-container {
    margin: 20px 0px;
    border-radius: 4px;
    background: var(--primary);
    .relative {
      position: relative;
    }
    .user-delete-modal {
      display: flex;
      justify-content: center;
      .user-delete-inside {
        display: flex;
        color: var(--secondary-text-color);
        font-family: Quicksand;
        flex-direction: column;
        background: var(--dropdown-backgroud);
        border-radius: 4px;
        width: 400px;
        height: 220px;
        padding: 25px;
        .modal-data {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          p {
            font-size: 14px;
          }
        }
        .modal-header {
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 10px;
          text-align: left;
        }
      }
      .actions {
        padding-top: 20px;
        text-align: end;
        .button {
          height: 32px;
          border: none;
          font-size: 14px;
          width: 75px;
        }
        .button:nth-child(1) {
          margin-left: 5px;
          background-color: transparent;
          color: var(--secondary-text-color);
          border: 1px solid var(--primary-border-color);
          &:hover {
            border: 2px solid var(--primary-border-color);
            transition: border 0.3s ease-out;
          }
          &:active {
            background-color: #7f8fa466;
            transition: background-color 0.3s ease-out;
          }
        }
        .button:nth-child(2) {
          margin-left: 5px;
          background-color: #ff0000;
          color: white;
          &:hover {
            background-color: #f64a4a;
            transition: background-color 0.3s ease-out;
          }
          &:active {
            background-color: #ee1111;
            transition: background-color 0.3s ease-out;
          }
        }
      }
    }
  }
</style>
